<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                        <v-card-title class="title">
                            Add/Edit ECA
                            <v-spacer></v-spacer>
                        </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title pb-0">

                            <v-flex xs5>
                                {{examName}}
                            </v-flex>
                            <v-flex xs3>
                                Grade :&nbsp;{{gradeName}}
                            </v-flex>
                        </v-card-title>
                    </v-card>
                    <v-data-table
                            :headers="headers"
                            :hide-default-footer="form.items.data.length<11"
                            :items="form.items.data"
                            footer-props.items-per-page-options="rowsPerPageItems"
                            :options.sync="pagination"
                            :server-items-length="form.items.meta.total"
                            :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">
                                    <strong>{{ item.name }}</strong>
                                </td>
                                <td class="text-xs-left">
                                    <div v-if="item.lists">
                                        <div class="eca" v-for="(data, i) in item.lists" :key="i">
                                            <div class="eca-list1">
                                                <span>
                                                    {{data.name}}
                                                </span>
                                            </div>
                                            <div class="eca-list2">
                                                <v-checkbox
                                                        :value="ecaListIds.includes(data.id) ? 1: 0"
                                                        @change="checkBoxChange($event,data.id)"></v-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template slot="footer">
                            <tr>
                                <td :colspan="3" class="text-xs-right">
                                    <v-btn color="success" outlined @click="save">Save</v-btn>
                                </td>

                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    export default {
        data: () => ({
            form: new Form({
                exam_id: '',
                grade_id: '',
                eca_list_ids: [],
            }, '/api/eca-exam-list/add-remove'),
            search: null,
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {
                rowsPerPage: 100,
            },
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'ECA', align: 'left', value: 'name'},
                {text: 'List', align: 'left', value: 'list'},
            ],
            exams: [],
            grades: [],
            exam_id: '',
            grade: '',
            gradeName: '',
            examName: '',
            loadingGrade: false,
            ecaListIds: [],

        }),

        computed: {
            ...mapState(['batch'])
        },

        watch: {},
        mounted() {
            this.get();
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&gradeId=' + this.$route.params.gradeId + '&examId=' + this.$route.params.examId;
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total;
                    this.examName = data.name.examName;
                    this.gradeName = data.name.gradeName;
                    this.ecaListIds = data.eca_list_id;
                })
            },

            save() {
                this.$rest.post('/api/eca-exam-list', {
                    grade_id: this.$route.params.gradeId,
                    exam_id: this.$route.params.examId,
                    eca_list_ids: this.ecaListIds,
                }).then(res => {
                    this.get();
                    this.$events.fire('notification', {message: res.data.message, status: 'success'});
                }).catch(e => {
                    this.$events.fire('notification', {message: e.response.data.message, status: 'error'});
                });
            },
            checkBoxChange(e, id) {
                if (e) this.ecaListIds.push(id);
                else {
                    var index = this.ecaListIds.indexOf(id);
                    if (index !== -1) this.ecaListIds.splice(index, 1);
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    .eca {
        display: flex;
    }

    .eca-list1 {
        flex: 2;
    }

    .eca-list2 {
        flex: 1;
    }
</style>